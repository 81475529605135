import { useEffect } from "react";

export const InitialAos = (Aos, options = {}) => {
  useEffect(() => {
    Aos.init({
      duration: options.duration || 1000, // Default animation duration
      offset: options.offset || 100, // Default offset
      once: options.once || false, // Trigger only once
      ...options, // Spread additional options
    });

    // Optional refresh if DOM updates
    return () => Aos.refresh();
  }, [Aos, options]);
};
