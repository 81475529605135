import Home from 'pages/Home'
import Projects from 'pages/Projects'
import {createBrowserRouter as createBR} from 'react-router-dom'

const router = createBR([
    {
        path: '/',
        element: <Home/>,
    },

    {
        path: '/projects',
        element: <Projects/>,
    },
])

export default router