
export const colors = {
    title: ' #ffde87', // Page
    subTitle:' #a33f3a', // red

    des: ' silver',

    link: ' white',
    link_hover: ' gray',

    btn: ' #a33f3a', // red
    btn_bg: ' #ffde87', // page
    btn_hover_bg: ' #1f1f1f', // black

    bg: `linear-gradient(90deg ,rgb(13, 13, 13), rgb(0, 0, 0))`, // black2

    bg_hover: ' #1f1f1fb1', // black transparent
    bg_hover2: 'rgba(31, 31, 31, 0.79)', // black transparent
    after_hover: ' #d0865834', // red transparent
    shadow: '1px 2px 4px 1px #ffdd87b8', // transparent
    shadow_color: ' #ffdd87b8',
    
    whatsApp: ' #5fffa2',
    facebook: ' #4fa4ff'
}

export const themes = {
    fontFamily: `"Cairo", sans-serif`,
}

export const media = {
    mobile: '480px',
    tablet: '900px',
    desktop: '1024px',
}