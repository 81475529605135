import React, { useState, useEffect } from "react";
import StartUpLoader from "pages/StartUp";
import styled from "@emotion/styled";
import { RouterProvider } from "react-router-dom";
import router from "libs/router";

function App() {
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    let LoaderTimer = setTimeout(() => setLoader(false), 4000);

    return () => clearTimeout(LoaderTimer);
  }, []);

  if (loader) {
    return <StartUpLoader setLoader={setLoader} />;
  }

  return (
    <Container>
      <RouterProvider router={router} />
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  direction: rtl;
  text-align: right;
`;

export default App;
